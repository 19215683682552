import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

export default () => (
  <DefaultLayout
    pageTitle="Code of Conduct"
    pageDescription="&yet Code of Conduct"
    bodyClass="conduct"
  >
    <div className="container">
      <section>
        <div className="content-narrower">
          <h1 className="title">Code of Conduct</h1>
          <p>
            Participating in our events means agreeing to abide by these
            standards, as a commitment to mutual respect, inclusion, openness,
            support, and community.
          </p>
          <h3>Acknowledgements</h3>
          <p>
            We are grateful to many people, communities, and sources for sharing
            their codes of conduct and anti-harassment policies openly, and with{' '}
            <a href="https://creativecommons.org/licenses/by/4.0/">
              Creative Commons Attribution
            </a>{' '}
            licenses. Our sources and inspiration include{' '}
            <a href="https://geekfeminism.wikia.org/wiki/Community_anti-harassment/Policy">
              Geek Feminism
            </a>{' '}
            and{' '}
            <a href="https://www.meshcommunities.us/code-of-conduct">
              Mesh Communities
            </a>
            , whose CoC in turn credits several other sources.
          </p>
          <h3>Intent</h3>
          <p>
            &yet is dedicated to providing a safe, inclusive, and
            harassment-free experience for everyone, regardless of gender,
            gender identity and expression, sexual orientation, disability,
            physical appearance, body size, age, race, or religion. We strive to
            create a community grounded in equality, respect, and mutual
            support, and we do not tolerate harassment of our community members
            in any form.
          </p>
          <p>
            It is our collective responsibility to ensure that each member of
            this community feels welcomed and respected. Harassment,
            discrimination, violence and bigotry create feelings of fear,
            uneasiness, humiliation and discomfort. Even if our actions are not
            intentionally hurtful, what we say and do can hurt others or make
            them feel uncomfortable.
          </p>
          <h3>Scope</h3>
          <p>
            This code of conduct applies to all &yet spaces, including our
            events and in any communication with &yet community members, both
            online and off. Anyone who violates this code of conduct may be
            sanctioned or expelled from these spaces at the discretion of the
            &yet community moderators, including without a refund for paid
            events.
          </p>
          <h3>Expected behavior</h3>
          <ul>
            <li>
              <strong>Treat everyone with respect and consideration.</strong> Be
              respectful and mindful in your critique of ideas and opinions. Cut
              one another some slack. Not everyone comes from the same shared
              knowledge base or social framework.
            </li>
            <li>
              <strong>
                Look for opportunities to learn from, uplift and affirm each
                other.
              </strong>{' '}
              Ask questions and share insights with an open heart and mind.
            </li>
            <li>
              <strong>Make space for others in your conversations.</strong> If
              you notice people dominating a discussion, ask for feedback from
              quieter members.
            </li>
            <li>
              <strong>
                Consider the difference between intent and impact.
              </strong>{' '}
              If someone tells you that the impact of your behavior has been
              hurtful, take responsibility for that impact, even if your
              intentions were positive.
            </li>
            <li>
              <strong>Participate in an authentic and active way.</strong> In
              doing so, you contribute to the health and longevity of your
              communities.
            </li>
            <li>
              <strong>
                Use gender-inclusive language. Avoid ableist language.
              </strong>{' '}
              Refrain from demeaning, discriminatory, or harassing behavior and
              speech. Avoid making assumptions about pronouns, gender, or sexual
              orientation.
            </li>
            <li>
              <strong>
                Before sharing anything shared in this community with another
                community or site, ask the original poster for their consent.
              </strong>{' '}
              When asking consent, confirm whether they are comfortable having
              content attributed to them or if they prefer you to anonymize it.
            </li>
            <li>
              <strong>Look out for other participants.</strong> Alert moderators
              or admin if you notice violations of this Code of Conduct, even if
              they seem inconsequential.
            </li>
          </ul>
          <h3>Unacceptable behavior</h3>
          <p>
            Unacceptable behaviors include intimidating, harassing, abusive,
            discriminatory, intentional misgendering, derogatory or demeaning
            speech or actions by any participant in our community online,
            whether in public channels or private messages, as well as in
            interactions between community members on other platforms or
            offline. Even if our actions are not intentionally hurtful, what we
            say and do can hurt others or make them feel uncomfortable.
          </p>
          <ul>
            <li>
              <strong>Harassment</strong> includes:
              <li>
                Harmful or prejudicial verbal or written comments related to
                gender, gender identity and expression, sexual orientation,
                disability, mental illness, neuro(a)typicality, physical
                appearance, body size, age, race, national origin, ethnic
                origin, nationality, immigration status, language, religion or
                lack thereof, or another identity marker. This includes
                anti-Indigenous/Nativeness and anti-Blackness.
              </li>
              <li>Unwelcome sexual attention.</li>
              <li>
                Physical contact and simulated physical contact (eg. textual
                descriptions like “*hug*” or “*backrub*”) without consent or
                after a request to stop.
              </li>
              <li>
                Continued one-on-one communication after a request to stop.{' '}
              </li>
              <li>Publication of non-harassing private communication.</li>
              <li>
                Harassing photography or recording, including logging online
                activity for harassment purposes.
              </li>
              <li>
                Threats of violence, or incitement of violence towards any
                individual, including encouraging a person to commit suicide or
                to engage in self-harm.
              </li>
              <li>
                Any verbal, written, or physical conduct designed to threaten,
                intimidate, or coerce another community member.{' '}
              </li>
              <li>
                Deliberate misgendering or use of ‘dead’ or rejected names.
              </li>
              <li>
                Gratuitous or off-topic sexual images or behaviour in spaces
                where they’re not appropriate.
              </li>
              <li>Stalking or following.</li>
              <li>
                Pattern of inappropriate social contact, such as
                requesting/assuming inappropriate levels of intimacy with others
              </li>
              <li>
                Deliberate “outing” of any aspect of a person’s identity without
                their consent except as necessary to protect vulnerable people
                from intentional abuse.
              </li>
            </li>
            <li>
              <strong>Spamming</strong> communities with frequent, irrelevant,
              or inappropriate content. Please avoid repeatedly posting the same
              thing or overwhelming communities with your content.
            </li>
            <li>
              <strong>Unwelcome comments</strong> regarding a person’s lifestyle
              choices and practices, including those related to food, health,
              parenting, relationships, drugs, political affiliations, and
              employment.
              <li>
                Questioning or challenging someone’s stated self-identity or
                chosen labels, even if they conflict with your own views. (For
                example, discussions about bi vs. pan, trans vs trans*, or
                whether grey/demisexual people are asexual, even if
                well-intentioned.){' '}
              </li>
              <li>
                Making general statements about groups you do not belong to.
              </li>
              <li>Minimizing other people’s experiences. </li>
              <li>
                Microaggressions, i.e. small, subtle, often subconscious actions
                that marginalize people from oppressed groups.{' '}
              </li>
            </li>
            <li>
              <strong>Trolling,</strong> i.e. sustained disruption of
              conversations without serving any legitimate purpose.
            </li>
          </ul>
          <h3>
            &yet prioritizes marginalized people’s safety over privileged
            people’s comfort.
          </h3>
          <p>We will not act on complaints regarding:</p>
          <ul>
            <li>
              “Reverse” -isms, including “reverse racism,” “reverse sexism,” and
              “cisphobia”
            </li>
            <li>
              Reasonable communication of boundaries, such as “leave me alone,”
              “go away,” or “I’m not discussing this with you.”
            </li>
            <li>Communicating in a “tone” you don’t find congenial</li>
            <li>
              Criticizing racist, sexist, cissexist, or otherwise oppressive
              behavior or assumptions
            </li>
          </ul>
          <h3>When something happens</h3>
          <p>
            If you are being harassed by a member of the community, notice that
            someone else is being harassed, or have any other concerns, please
            contact the &yet community moderators at{' '}
            <a href="mailto:community@andyet.com">community@andyet.com</a>.
          </p>
          <p>If you see a Code of Conduct violation, follow these steps:</p>
          <ol>
            <li>
              Let the person know that what they did is not appropriate and ask
              them to stop and/or edit their content. That person should
              immediately stop the behavior and correct the issue.{' '}
            </li>
            <li>
              If this doesn’t happen, or if you’re uncomfortable speaking up,
              report the content to the community moderators. As soon as
              available, a community leader or moderator will join, identify
              themselves, and take further action, where applicable, and resolve
              the issue.
            </li>
            <li>
              When reporting, please include any relevant details, links,
              screenshots, context, or other information that may be used to
              better understand and resolve the situation.{' '}
            </li>
            <li>
              If you are being harassed by a member of the &yet community,
              notice that someone else is being harassed, or have any other
              concerns, please contact the administrators at
              community@andyet.com. If the person who is harassing you is on the
              team, they will recuse themselves from handling your incident. We
              will respond as promptly as we can.
            </li>
          </ol>
          <p>
            Reports will be handled by &yet community moderators. In this
            situation, you may also reach out to an individual moderator instead
            of submitting a report by email, or have a friend submit your
            report. Otherwise, please do not reach out to private email
            addresses or social media accounts for reporting harassment.
          </p>
          <p>We will respond as promptly as we can.</p>
          <p>
            Reporting should not be done via social media or public discussion
            in the &yet space.
          </p>
          <p>
            This code of conduct applies to &yet’s online event space, but if
            you are being harassed by a member of the community outside our
            spaces, we still want to know about it. We will take all good-faith
            reports of harassment by &yet community members, especially &yet
            community moderators, seriously. This includes harassment outside
            our spaces and harassment that took place at any point in time. The
            abuse team reserves the right to exclude people from the &yet
            community based on their past behavior, including behavior outside
            &yet spaces and behavior towards people who are not in the &yet
            community.
          </p>
          <p>
            We reserve the right to reject any report we believe to have been
            made in bad faith. Reports intended to silence legitimate criticism
            may be deleted without response.
          </p>
          <h3>Privacy and confidentiality</h3>
          <p>
            Respecting each other’s privacy is essential to a healthy, trusting
            community. Please don’t repeat or quote things said here without the
            affirmative consent of the speaker(s). Do not identify others as
            members of any community without their consent.
          </p>
          <p>
            Please be mindful that things you say here may at some point become
            public. We cannot prevent people from screencapping or otherwise
            sharing content. Please exercise caution and refrain from sharing
            sensitive information that could harm you or others if it became
            public. &yet is a U.S. company and therefore subject to subpoenas
            from U.S. courts. Our logs may be subject to subpoena and could
            become public as part of legal proceedings. By joining our
            community, you are opting into these conditions.
          </p>
          <p>
            We will respect confidentiality requests for the purpose of
            protecting victims of abuse. At our discretion, we may publicly name
            a person about whom we’ve received harassment complaints, or
            privately warn third parties about them, if we believe that doing so
            will increase the safety of &yet community members or the general
            public. We will not name harassment victims without their
            affirmative consent.
          </p>
          <h3>Consequences</h3>
          <p>
            Unacceptable behavior from any community member, including those
            with decision-making authority (community leaders and moderators),
            will not be tolerated. Participants asked to stop any harassing
            behavior are expected to comply immediately.
          </p>
          <p>
            If a participant engages in unacceptable behavior, &yet community
            moderators may take any action they deem appropriate, up to and
            including a temporary ban or permanent expulsion from all &yet
            spaces with no warning (and without refund of fees when applicable).
            &yet may choose to identify participants who violate our policies
            against abuse and harassment to other &yet community members or the
            general public. We may also choose to share that harassing or
            abusive content if we feel it is best for the safety of the
            community.
          </p>
          <p>
            The intention of your behavior is not as important as the impact. We
            have no discussion about how your behavior was meant. What we care
            about is how it makes our participants feel. If you feel you have
            been falsely or unfairly accused of violating this Code of Conduct,
            you should notify one of the admins via email with a concise
            description of your grievance. Your grievance will be handled in
            accordance with our existing governing policies.{' '}
          </p>
          <h3>Attribution</h3>
          <p>
            This code of conduct is adapted from several sources including{' '}
            <a href="https://geekfeminism.wikia.org/wiki/Community_anti-harassment/Policy">
              Geek Feminism’s Community Anti-Harassment Policy
            </a>{' '}
            and{' '}
            <a href="https://www.meshcommunities.us/code-of-conduct">
              Mesh Communities’ code of conduct
            </a>
            . We have adapted these codes of conduct for our own use under the
            terms of the{' '}
            <a href="https://creativecommons.org/licenses/by/4.0/">
              Creative Commons Attribution License
            </a>
            . Please build on this for your own purposes, and suggest
            improvements.
          </p>
        </div>
      </section>
    </div>
  </DefaultLayout>
);
